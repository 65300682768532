<template>
  <div class="login-main">
    <div class="login-title">学分申领须知</div>
    <div class="login-tip"><p>各位参会代表：</p>
      <br />
      <p>
        本次大会申领“感染科医师能力提升培训班”学分【项目编号：2021-03-08-153(国)】，会议时间：2022年9月2日-4日。线上学员完成线上缴费、在线学习时长大于70%且考核合格者将授予国家级学分4分，感谢您的参与！
      </p>
      <br />
      <p>
        点击下方按钮，根据页面引导完成登录，我们将会记录您观看直播的学习时长和答题情况，期待您的参与。
      </p>
      <br />
      <p>
        注：本次培训班共计2天（9月2日下午-4日上午，共4个半天），每个半天需要登录在线学习（登录4次），时长不可补，线上学员考试合格后方能申请证书。根据省继教委相关要求，浙江省内学员会议期间需要扫描线下动态二维码。会议结束30个工作日后官网【“中国医师协会继续医学教育网”（<a href="http://www.cmdacme.com">http://www.cmdacme.com</a>）或"国家级CME项目网上申报及信息反馈系统"（<a href="https://cmegsb.cma.org.cn">https://cmegsb.cma.org.cn</a>）】自行申领证书。证书领取流程建议截图留存备用。
      </p>
    </div>
    <van-button class="choice-btn" @click="login()">登 录</van-button>
  </div>
</template>
<script>
import router from "../../router";
import { Field, Button, Toast } from "vant";
import Vue from "vue";
import axios from "axios";
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
export default {
  components: {},
  data() {
    return {
      isMobile: false,
     
    };
  },
  created() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    checkDevice() {

    },
    login() {
      if (this.isMobile) {
        window.location.href =
          "http://m.cnconf.com/mini_site/go/index/cn/4720?from=shulan";
      } else {
        window.location.href =
          "http://grys2022.cnconf.com/muser/go/index/cn/?from=shulan";
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;

}

@media only screen and (min-width: 1000px) {
  @function fit($num) {
    @return $num / 3;
  }
  .login-main {
    margin: 0 auto;
    background: #f5f5f9;
    width: fit(1000px);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }

    .login-title {
    font-size: fit(44px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
    line-height: fit(50px);
    margin: fit(44px) auto fit(20px);
  }
  .login-tip {
    font-size: fit(30px);
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #040814;
    line-height: fit(50px);
    padding: 0 fit(40px);
  }
  .choice-btn {
    background: #12beae;
    border-radius: fit(20px);
    width: fit(690px);
    height: fit(94px);
    margin: fit(20px) auto;
    line-height: fit(70px);
    text-align: center;
    font-size: fit(36px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: block;

  }


}

@media only screen and (max-width: 1000px) {
  @function fit($num) {
    @return $num;
  }
  .login-main {
    background: #f5f5f9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-title {
    font-size: 44px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #040814;
    line-height: 50px;
    margin: 44px auto 46px;
  }
  .login-tip {
    font-size: 30px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #040814;
    line-height: 50px;
    padding: 0 40px;
  }
  .choice-btn {
    background: #12beae;
    border-radius: fit(20px);
    width: fit(690px);
    height: fit(94px);
    margin: 5px auto 30px;
    line-height: fit(70px);
    text-align: center;
    font-size: fit(36px);
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    display: block;
    position:fixed;
    bottom: 50px;
  }
}
</style>
